
import { defineComponent } from "vue";
import { client } from '@/client';
import { ElMessage } from "element-plus";
import { FriendGroupsData } from "@/shared/types/FriendsData";
  export default defineComponent({
    name: "group-add",
    components: {},
    data: () => {
      return {
        params: {} as FriendGroupsData,
        paramsRules: {
          //校验规则
          groupName: [
            { required: true, message: "请输入栏目名称", trigger: "blur" },
            {
              min: 2,
              max: 50,
              message: "名称长度在 2 到 50 个字符之间",
              trigger: "blur",
            },
          ],
        },
      };
    },
    created() {
        let id = this.$route.params.id as string;
        this.params.userId = parseInt(id);
    },
    methods: {
      //新增
      async create() {
        try {
          let res = await client.callApi("group/Add",this.params);
          if (res.isSucc) {
            ElMessage({
              message: "新增成功^_^",
              type: "success",
            });
            this.$router.go(-1);
          }
        } catch (error) {
          console.log(error);
        }
      },
      submit(formName: string) {
        let refs:any = this.$refs[formName];
  
        refs.validate((valid: any) => {
          if (valid) {
            this.create();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
    },
  });
  